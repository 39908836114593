import React from 'react';
import PropTypes from 'prop-types';

const Button = (props) => {
  const { label, onClick, type } = props;
  return (
    <button
      className={`flex items-center justify-center text-center w-full font-semibold py-3 my-2 ${
        type === 'disabled'
          ? 'bg-light-gray-2 text-gray-base'
          : type === 'backButton'
          ? 'border border-light-gray'
          : type === 'confirm'
          ? 'text-white bg-dark-red'
          : 'bg-dark-2 text-white'
      }  rounded-md`}
      onClick={type !== 'disabled' ? onClick : undefined}
    >
      {label}
    </button>
  );
};

Button.defaultProps = {
  label: 'Button',
  type: 'default',
  onClick: () => {
    console.log('Button click');
  },
};
Button.propTypes = {
  label: PropTypes.string,
  type: PropTypes.oneOf(['disabled', 'backButton', 'confirm', 'default']),
  onClick: PropTypes.func.isRequired,
};

export default Button;
