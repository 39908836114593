import { configureStore } from '@reduxjs/toolkit';
import orderReducer from './redux/order/orderSlice';
import issueReducer from './redux/issue/issueSlice';
import userReducer from './redux/user/userSlice';
import lineReducer from './redux/line/lineSlice';

export const store = configureStore({
  reducer: {
    order: orderReducer,
    issue: issueReducer,
    user: userReducer,
    line: lineReducer,
  },
});
