export const axiosErrorHandler = (error) => {
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    if (error.response.data.code) {
      switch (error.response.data.code) {
        case -1000:
          error.response.data.message = 'LineUid ไม่ถูกต้อง';
          break;
        case 1000:
          error.response.data.message = 'Issue not found';
          break;
        case 1002:
          error.response.data.message = 'ไฟล์รูปภาพไม่ถูกต้อง';
          break;
        default:
          error.response.data.message = 'ไม่พบข้อมูล';
      }
    }
    return error.response.data;
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    return error.request;
  } else {
    return error.message;
  }
};
