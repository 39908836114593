import React from 'react';
import liff from '@line/liff';
import Button from '../components/Buttons';
import Title from '../components/Title';
import { useNavigate } from 'react-router-dom';
import { IconSuccess } from '../components/Icons';
import { useDispatch } from 'react-redux';
import { reset } from '../redux/issue/issueSlice';

const Thankyou = () => {
  const navigation = useNavigate();
  const dispatch = useDispatch();

  return (
    <div className="max-w-3xl mx-auto mt-4 tablet:px-20">
      <Title title="แจ้งปัญหาให้แอดมิน" />
      <div className="mx-auto bg-white pt-4 pb-6 px-4 mt-4 mb-8 rounded-lg text-center">
        <div className="mt-4 mb-6 flex justify-center">
          <IconSuccess width="72" height="72" alt="IconSuccess" />
        </div>
        <span className="text-xl font-bold">
          ระบบได้บันทึกข้อมูลของคุณไว้แล้ว
        </span>
        <div className="text-dark my-8">
          แอดมินจะทำการตรวจสอบ <br />
          และดำเนินการติดต่อคุณกลับโดยเร็วที่สุด
        </div>
        <Button label="ปิดศูนย์ช่วยเหลือ" onClick={() => liff.closeWindow()} />
        <Button
          label="ย้อนกลับ"
          type="backButton"
          onClick={() => {
            dispatch(reset());
            navigation('/');
          }}
        />
      </div>
    </div>
  );
};

export default Thankyou;
