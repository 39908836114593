import React, { Fragment } from 'react';

const Title = ({ title, subTitle }) => {
  return (
    <Fragment>
      <div
        className={`text-xl text-white font-bold ${
          subTitle ? 'ml-4' : 'text-center pb-4'
        }`}
      >
        {title}
      </div>
      <div className="text-base text-white mt-2 ml-4 font-bold">{subTitle}</div>
    </Fragment>
  );
};

export default Title;
