import { createSlice } from '@reduxjs/toolkit';

export const lineSlice = createSlice({
  name: 'line',
  initialState: {
    loading: false,
    lineUser: {},
    error: null,
  },
  reducers: {
    loading: (state) => {
      state.loading = true;
      state.lineUser = {};
      state.error = null;
    },
    setLineUser: (state, action) => {
      state.loading = false;
      state.lineUser = action.payload;
    },
    setError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { loading, setLineUser, setError } = lineSlice.actions;

export const selectLineUser = (state) => state.line.lineUser;
export const selectLineLoading = (state) => state.line.loading;

export default lineSlice.reducer;
