import production from './production';
import staging from './staging';
import base from './base';
const envConfig = {
  staging: staging,
  production: production,
};

const stage = process.env.REACT_APP_STAGE;
const config = {
  ...base,
  ...envConfig[stage],
};
export default config;
