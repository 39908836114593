import React, { Fragment, useEffect, useState } from 'react';
import { func } from 'prop-types';
import { IconClose, IconImage } from './Icons';
import heic2any from 'heic2any';
import { useDispatch } from 'react-redux';
import { reset } from '../redux/issue/issueSlice';
import Popup from './Popup';
import Button from './Buttons';
import { IconError } from './Icons';

const UploadImage = (props) => {
  const { imageFileCallback } = props;

  const [fileName, setFileName] = useState('แนบรูปภาพที่เกี่ยวข้อง');
  const [isPopup, setIsPopup] = useState(false);
  const [selectedImage, setSelectedImage] = useState(undefined);
  const [previewImage, setPreviewImage] = useState(undefined);
  const dispatch = useDispatch();

  const truncate = (fullStr, strLen) => {
    if (fullStr.length <= strLen) return fullStr;
    const separator = '...';

    var sepLen = separator.length,
      charsToShow = strLen - sepLen,
      frontChars = Math.ceil(charsToShow / 2);

    return (
      fullStr.substr(0, frontChars) +
      separator +
      fullStr.substr(fullStr.length - 10)
    );
  };

  const onSelectImage = async (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedImage(undefined);
      return;
    }

    if (e.target.files[0].size > 6000000) {
      // TODO: show modal error size
      resetImage();
      setIsPopup(true);
      return;
    }

    const type = e.target.files[0].type;
    if (type == 'image/heic') {
      const convertedImage = await convertHeicToJpeg(e.target.files[0]);
      setSelectedImage(convertedImage);
      imageFileCallback(convertedImage);
      setFileName(truncate(convertedImage.name, 25));
    } else {
      setSelectedImage(e.target.files[0]);
      imageFileCallback(e.target.files[0]);
      setFileName(truncate(e.target.files[0].name, 25));
    }
  };

  useEffect(() => {
    if (!selectedImage) {
      setPreviewImage(undefined);
      return;
    }

    const o = URL.createObjectURL(selectedImage);
    setPreviewImage(o);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(o);
  }, [selectedImage]);

  const convertHeicToJpeg = async (image) => {
    try {
      // TODO: loading bar
      const out = await heic2any({
        blob: image,
        toType: 'image/jpeg',
        quality: 0.8,
      });

      const regex = /(heic||HEIC)$/;
      const newFile = new File([out], image.name.replace(regex, 'jpeg'));
      return newFile;
    } catch (e) {
      // todo handle error
      return e;
    }
  };

  const resetImage = () => {
    setSelectedImage(undefined);
    setFileName('แนบรูปภาพที่เกี่ยวข้อง');
    dispatch(reset());
    imageFileCallback(null);
  };

  return (
    <Fragment>
      <div className="mt-4 mb-2 ml-2  font-semibold text-base">
        รูปภาพที่เกี่ยวข้อง
      </div>
      <input
        type="file"
        id="actual-btn"
        accept="image/*"
        name="payment-file"
        onChange={onSelectImage}
        hidden
      />
      <div className="flex items-center justify-between relative">
        <span className="w-full rounded-md border border-light-gray px-4 py-3 overflow-hidden text-ellipsis whitespace-nowrap">
          {previewImage ? (
            <span className="text-super-black">{fileName}</span>
          ) : (
            <span className="text-gray-base">{fileName}</span>
          )}
        </span>
        <label
          htmlFor="actual-btn"
          className="bg-dark-red text-white rounded-md absolute right-0 p-3 text-center font-semibold"
        >
          เลือกรูปภาพ
        </label>
      </div>
      <span className="text-light-red text-lg">
        สามารถอัปโหลดไฟล์สูงสุดไม่เกิน 6 MB
      </span>

      <div className="mt-5 mb-2 ml-2  font-semibold text-lg">
        รูปภาพที่เลือก
      </div>
      <div className="w-full mt-2 mb-6 rounded-md border border-gray-base border-dashed h-full flex justify-center items-center min-h-[170px]">
        {previewImage ? (
          <Fragment>
            <div className="relative bg-inherit">
              <span
                onClick={() => resetImage()}
                className="absolute p-2 opacity-70 z-10 flex bg-dark-gray rounded-3xl top-2 right-2"
              >
                <IconClose fill="#fff" width="20" height="20" />
              </span>
              <img src={previewImage} alt="slip preview" className="mb-4" />
            </div>
          </Fragment>
        ) : (
          <IconImage />
        )}
      </div>
      {isPopup && <Popup
        icon={<IconError width="72" height="72" alt="IconError" />}
        title="เกิดข้อผิดพลาด"
        description="ขนาดรูปภาพต้องไม่เกิน 6 MB"
        confirmButton={<Button label="รับทราบ" onClick={() => setIsPopup(false)} />}
      />}
    </Fragment>
  );
};

UploadImage.propTypes = {
  imageFileCallback: func,
};

export default UploadImage;
