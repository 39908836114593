import { createSlice } from '@reduxjs/toolkit';
// import issueService from '../../services/issueService';
import orderService from '../../services/orderService';
import { axiosErrorHandler } from '../../utils/error/handle';

export const orderSlice = createSlice({
  name: 'order',
  initialState: {
    loading: false,
    orders: [],
    error: null,
  },
  reducers: {
    loading: (state) => {
      state.loading = true;
      state.orders = [];
      state.error = null;
    },
    setOrders: (state, action) => {
      state.loading = false;
      state.orders = action.payload;
    },
    setError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { loading, setOrders, setError } = orderSlice.actions;

export const getOrders = (payload) => async (dispatch) => {
  try {
    dispatch(loading());
    const result = await orderService.GetOrders(payload);
    dispatch(setOrders(result.data.orders));
  } catch (e) {
    dispatch(setError(axiosErrorHandler(e)));
  }
};

export const selectOrders = (state) => state.order.orders;
export const selectOrdersLoading = (state) => state.order.loading;

export default orderSlice.reducer;
