import config from '../config';
import api from '../utils/refreshTokenService';

const issueService = {
  CreateIssue: async (payload) => {
    let formData = new FormData();
    if (payload?.lineUid) {
      formData.append('lineUid', payload.lineUid);
    }
    if (payload?.orderId) {
      formData.append('orderId', payload.orderId);
    }
    if (payload?.topic) {
      formData.append('topic', payload.topic);
    }
    if (payload?.channel) {
      formData.append('channel', payload.channel);
    }
    if (payload?.message) {
      formData.append('message', payload.message);
    }
    if (payload?.userId) {
      formData.append('userId', payload.userId);
    }
    if (payload?.images) {
      formData.append('images', payload.images);
    }
    return await api.post(`${config.baseAPI}/issues`, formData);
  },
};

export default issueService;
