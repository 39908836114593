import React, { Fragment } from 'react';
import { arrayOf, func, number, shape, string } from 'prop-types';

const OrderedLists = (props) => {
  const { items, selectOrderNo, selectOrderNoCallback, required } = props;

  return (
    <Fragment>
      <div className="mt-4 mb-2 ml-2  font-semibold text-lg">
        หมายเลขคำสั่งซื้อที่เกี่ยวข้อง{' '}
        <span className="text-light-red">{required && '*'}</span>
      </div>
      <select
        className={`w-full rounded-md border border-light-gray p-3 bg-white ${
          selectOrderNo ? 'text-super-black' : 'text-gray-base'
        }`}
        value={selectOrderNo}
        onChange={selectOrderNoCallback}
      >
        <option value="" disabled hidden>
          เลือกหมายเลขคำสั่งซื้อ
        </option>
        {items?.map((item, idx) => {
          return (
            <option key={idx} value={item.orderNo}>
              {`${item.orderNo} / ยอดคำสั่งซื้อ ${item.totalPrice} บาท`}
            </option>
          );
        })}
      </select>
    </Fragment>
  );
};

OrderedLists.propTypes = {
  selectOrderNoCallback: func,
  selectOrderNo: string,
  items: arrayOf(
    shape({
      id: string,
      orderNo: number,
      total: number,
      totalPrice: number,
    })
  ),
};

export default OrderedLists;
