import config from '../config';
import api from '../utils/refreshTokenService';

const userService = {
  GetUser: async (payload) => {
    const { data } = api.get(`${config.baseAPI}/user`, {
      headers: {
        lineUid: payload.lineUid,
      }
    })
    return data;
  },
  GetProfile: async () => {
    const { data } = await api.get(`${config.apiGatewayUrl}/api/v1/users/me`)

    return data;
  },
};

export default userService;
