import { createSlice } from '@reduxjs/toolkit';
import userService from '../../services/userService';
import { axiosErrorHandler } from '../../utils/error/handle';

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    loading: false,
    userInfo: {},
    error: null,
  },
  reducers: {
    loading: (state) => {
      state.loading = true;
      state.userInfo = {};
      state.error = null;
    },
    setUserInfo: (state, action) => {
      state.loading = false;
      state.userInfo = action.payload;
    },
    setError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { loading, setUserInfo, setError } = userSlice.actions;

export const getUserInfo = (payload) => async (dispatch) => {
  try {
    dispatch(loading());
    const result = await userService.GetUser(payload);
    dispatch(setUserInfo(result.data));
  } catch (e) {
    dispatch(setError(axiosErrorHandler(e)));
  }
};

export const selectUserInfo = (state) => state.user.userInfo;
export const selectUserInfoLoading = (state) => state.user.loading;

export default userSlice.reducer;
