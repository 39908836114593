import React, { Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Button from '../components/Buttons';
import InputField from '../components/InputField';
import OrderedLists from '../components/OrderedLists';
import Title from '../components/Title';
import UploadImage from '../components/UploadImage';

import {
  selectOrders,
  getOrders,
  selectOrdersLoading,
} from '../redux/order/orderSlice';
import { selectLineUser } from '../redux/line/lineSlice';
import { selectUserInfo } from '../redux/user/userSlice';
import {
  createIssue,
  getIssue,
  reset,
  selectCreateIssueError,
  selectCreateIssueLoading,
} from '../redux/issue/issueSlice';
import Loading from '../components/Loading';
import SuccessPopup from '../components/SuccessPopup';

const Prices = () => {
  const navigation = useNavigate();
  const [details, setDetails] = useState('');
  const [selectOrderNo, setSelectOrderNo] = useState('');
  const [imageFile, setImageFile] = useState(null);

  const orders = useSelector(selectOrders);
  const lineUser = useSelector(selectLineUser);
  const userInfo = useSelector(selectUserInfo);
  const loadingCreateIssue = useSelector(selectCreateIssueLoading);
  const loadingOrder = useSelector(selectOrdersLoading);
  const errorCreateIssue = useSelector(selectCreateIssueError);
  const issueStatus = useSelector(getIssue);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getOrders({
        status: 'success',
        lineUid: lineUser.userId,
      })
    );
  }, []);

  const handleConfirm = () => {
    const body = {
      lineUid: lineUser.userId,
      orderId: selectOrderNo,
      topic: 'PRICES',
      channel: 'LINE',
      message: details,
      userId: userInfo.userId,
      images: imageFile,
    };
    dispatch(createIssue(body));
  };

  return (
    <Fragment>
      {loadingOrder && <Loading />}
      {loadingCreateIssue && <Loading />}
      <div className="max-w-3xl mx-auto mt-4 tablet:px-20">
        <Title title="แจ้งปัญหาให้แอดมิน" />
        <div className="mx-auto bg-white pt-4 pb-6 px-4 mt-4 mb-8 rounded-lg">
          <div className="text-center text-xl text-super-black font-bold mt-4 mb-6">
            หมวดหมู่ - การรับเงินรางวัล
          </div>
          <OrderedLists
            items={orders}
            selectOrderNo={selectOrderNo}
            selectOrderNoCallback={(e) => setSelectOrderNo(e.target.value)}
            require="*"
          />
          <InputField
            value={details}
            onChange={(e) => {
              if (!e.target.value.match(/^\s/)) {
                setDetails(e.target.value);
              }
            }}
          />
          <UploadImage imageFileCallback={setImageFile} />
          {errorCreateIssue && (
            <div className="text-center text-light-red">
              {errorCreateIssue.message}
            </div>
          )}
          <Button
            label="ยืนยันส่งข้อมูลให้แอดมิน"
            type={details && !errorCreateIssue ? 'confirm' : 'disabled'}
            onClick={() => handleConfirm()}
          />
          <Button
            label="ย้อนกลับ"
            type="backButton"
            onClick={() => {
              dispatch(reset());
              navigation('/');
            }}
          />
        </div>
      </div>
      {issueStatus.success && <SuccessPopup />}
    </Fragment>
  );
};

export default Prices;
