import React, { Fragment } from 'react';

const InputField = ({ value, onChange }) => {
  return (
    <Fragment>
      <div className="mt-4 mb-2 ml-2  font-semibold text-lg">
        รายละเอียด <span className="text-light-red"> *</span>
      </div>
      <textarea
        className="w-full rounded-md border border-light-gray px-4 pt-3 h-32"
        placeholder="ระบุรายละเอียดของปัญหาที่คุณพบ..."
        value={value}
        onChange={onChange}
      />
    </Fragment>
  );
};

export default InputField;
