import React, { Fragment } from 'react';
import { Card, CardOther } from '../components/Card';
import { useSelector } from 'react-redux';
import { selectLineUser } from '../redux/line/lineSlice';
import {
  IconAssistant,
  IconCall,
  IconCartColor,
  IconMoney,
  IconPrices,
  IconUser,
} from '../components/Icons';
import Title from '../components/Title';

const Home = () => {
  const lineUser = useSelector(selectLineUser);

  return (
    <Fragment>
      <div className="max-w-3xl mx-auto mt-4 tablet:px-20">
        <Title
          title={`สวัสดี ${lineUser.displayName || ''}`}
          subTitle="ต้องการให้เราช่วยเหลือเรื่องใด?"
        />
        <div className="mx-auto bg-white pt-4 pb-6 px-4 mt-4 mb-6 rounded-lg">
          <div className="text-center text-xl text-super-black font-semibold my-4">
            เลือกหมวดหมู่ของปัญหาที่คุณพบ
          </div>
          <div className="flex">
            <Card
              link="/account"
              marginRight="mr-1"
              title="บัญชีสมาชิกของฉัน"
              icon={<IconUser width="72" height="72" alt="IconUser" />}
            />
            <Card
              link="/buying"
              marginLeft="ml-1"
              title="การเลือกซื้อลอตเตอรี่"
              icon={<IconCartColor width="72" height="72" alt="IconCart" />}
            />
          </div>
          <div className="flex ">
            <Card
              link="/payment"
              marginRight="mr-1"
              title="การชำระเงิน"
              icon={<IconMoney width="72" height="72" alt="IconMoney" />}
            />
            <Card
              link="/prices"
              marginLeft="ml-1"
              title="การรับเงินรางวัล"
              icon={<IconPrices width="72" height="72" alt="IconPrices" />}
            />
          </div>
          <CardOther
            link="/other"
            textTop="ปัญหาอื่นๆ"
            textBottom="แอดมินจะตอบกลับภายใน 24 ชั่วโมง"
            icon={<IconAssistant width="48" height="48" alt="IconAssistant" />}
          />
        </div>

        <span className="text-xl text-super-black font-bold ml-2">
          ช่องทางติดต่ออื่นๆ
        </span>
        <div className="mx-auto bg-white pt-4 pb-6 px-4 mt-4 mb-8 rounded-lg">
          <div className="flex items-center mt-3">
            <div className="flex items-center justify-left mr-4">
              <IconCall width="32" height="32" alt="IconCall" />
            </div>
            <div className="text-xl  break-all font-bold">
              <span>086-757-7777</span>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Home;
