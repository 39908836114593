import axios from 'axios'
import config from '../config'
import Cookies from 'js-cookie'

let refreshFn = undefined

const ignoreRoutes = ['/auth/line/login', '/auth/url']

export const api = axios.create({
  baseURL: `${config.apiGateway}/api/v1`,
  headers: {
    'Content-Type': 'application/json',
    thereisnospoon: Cookies.get('thereisnospoon'),
  },
})

export const refreshToken = async () => {
  const res = await api.post(`${config.apiGatewayUrl}/api/v1/auth/refresh`, {
    refresh: Cookies.get('refreshToken'),
  })

  if (res.data.status.code !== 0) throw new Error(res.data.status.description)

  const {
    data: { access, refresh },
  } = res.data

  Cookies.set('accessToken', access, { domain: config.cookieDomain })
  Cookies.set('refreshToken', refresh, { domain: config.cookieDomain })
}

api.interceptors.request.use(
  (cfg) => {
    const token = Cookies.get('accessToken')
    if (token) {
      cfg.headers.Authorization = `Bearer ${token}`
    }
    return cfg
  },
  (err) => {
    return Promise.reject(err)
  }
)

api.interceptors.response.use(
  (res) => res,
  async (err) => {
    const config = err.config
    if (!ignoreRoutes.includes(config.url) && err.response && err.response.status === 401 && !config._retry) {
      try {
        // await same refresh function
        if (!refreshFn) {
          refreshFn = refreshToken()
        }

        await refreshFn

        config._retry = true
        try {
          return api(config)
        } catch (e) {
          return Promise.reject(e)
        }
      } catch (err) {
        Cookies.remove('accessToken', { domain: config.cookieDomain })
        Cookies.remove('refreshToken', { domain: config.cookieDomain })
      } finally {
        refreshFn = undefined
      }
    }
    return Promise.reject(err)
  }
)

export default api
