import React, { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import Home from './pages/Home.js';
import Account from './pages/Account.js';
import Thankyou from './pages/Thankyou.js';
import Payment from './pages/Payment.js';
import CardBody from './Layout/CardBody.js';
import Buying from './pages/Buying.js';
import Prices from './pages/Prices.js';
import Other from './pages/Other.js';
import liff from '@line/liff';
import { useDispatch, useSelector } from 'react-redux';
import { selectLineLoading, setLineUser } from './redux/line/lineSlice';
import {
  getUserInfo,
  selectUserInfoLoading,
  setUserInfo,
} from './redux/user/userSlice';
import config from './config';
import Cookies from 'js-cookie';
import userService from './services/userService';
import Popup from './components/Popup.js';
import Button from './components/Buttons.js';
import { IconError } from './components/Icons.js';
import Loading from './components/Loading.js';

function App() {
  const dispatch = useDispatch();
  const [isPopup, setIsPopup] = useState(false);
  const loadingUserInfo = useSelector(selectUserInfoLoading);
  const loadingLine = useSelector(selectLineLoading);

  useEffect(() => {
    const init = async () => {
      await liff.init({ liffId: config.liffId });
      if (liff.isInClient()) {
        const profile = await liff.getProfile();
        dispatch(setLineUser(profile));
        dispatch(
          getUserInfo({
            lineUid: profile.userId,
          })
        );
      } else if (Cookies.get('accessToken')) {
        try {
          const result = await userService.GetProfile();
          const { image, lineId, name, firstName, lastName, userId } =
            result.data;
          dispatch(
            setLineUser({
              userId: lineId,
              displayName: name,
              pictureUrl: image,
            })
          );
          dispatch(setUserInfo({ userId, firstName, lastName }));
        } catch (e) {
          console.error(e);
          setIsPopup(true);
        }
      } else {
        setIsPopup(true);
      }
    };
    init();
  }, []);

  const handleClickLogin = () => {
    setIsPopup(false);
    let parts = location.hostname.split('.')
    let upperleveldomain = parts.join('.')
    console.log({upperleveldomain});

    // window.location.href = config.redirectUrl + '/profile';
    window.location.href = 'https://' + upperleveldomain + '/profile'
  }

  console.log('..');

  return (
    <>
      {loadingUserInfo && <Loading />}
      {loadingLine && <Loading />}
      <div className="bg-gray-bg font-anuphan">
        <CardBody />
        <div className="relative p-4 min-h-screen">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/account" element={<Account />} />
            <Route path="/payment" element={<Payment />} />
            <Route path="/buying" element={<Buying />} />
            <Route path="/prices" element={<Prices />} />
            <Route path="/other" element={<Other />} />
            <Route path="/thankyou" element={<Thankyou />} />
            <Route path="/*" element={<Home />} />
          </Routes>
        </div>
        {isPopup && (
          <Popup
            icon={<IconError width="72" height="72" alt="IconError" />}
            title="ไม่สามารถเข้าสู่ระบบได้"
            description={
              <>
                กรุณากลับไปที่เว็บไซต์ ลอตเตอรี่พลัส และเข้าสู่ระบบใหม่อีกครั้ง
                <br />
                หรือ
                <br />
                ติดต่อแอดมิน
              </>
            }
            confirmButton={
              <Button label="กลับไป ลอตเตอรี่พลัส" onClick={handleClickLogin} />
            }
          />
        )}
      </div>
    </>
  );
}

export default App;
