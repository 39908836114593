import React from 'react';
import { Link } from 'react-router-dom';

export const Card = ({ marginRight, marginLeft, title, icon, link = '/' }) => {
  return (
    <div
      className={`bg-white rounded-md border border-gray-base mt-2 ${marginRight} ${marginLeft} w-full `}
    >
      <Link to={link}>
        <div className="flex justify-center pt-8 pb-2 fill-gray-base">
          {icon}
        </div>
        <div className="text-center text-xl  mb-4 font-bold">{title}</div>
      </Link>
    </div>
  );
};

export const CardOther = ({ icon, textTop, textBottom, link = '/' }) => {
  return (
    <Link to={link}>
      <div className="flex items-center bg-white rounded-md border border-gray-base mt-2">
        <div className="flex items-center justify-left fill-gray-base py-5 px-4">
          {icon}
        </div>
        <div className="">
          <span className="font-bold text-xl ">{textTop}</span>
          <br />
          <span className="text-base font-bold">{textBottom}</span>
        </div>
      </div>
    </Link>
  );
};
