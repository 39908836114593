import config from '../config';
import api from '../utils/refreshTokenService';

const orderService = {
  GetOrders: async (payload) => {
    const { data } = await api.get(`${config.baseAPI}/orders`, {
      params: {
        status: payload.status,
      },
      headers: {
        lineUid: payload.lineUid,
      }
    })
    return data;
  },
};

export default orderService;
