import React, { Fragment } from 'react';
import Logo from '../Assets/LOGO_RedPheonix_final.png';

const CardBody = () => {
  return (
    <Fragment>
      <div className="relative overflow-auto h-full"></div>
      <div className="absolute bg-gradient-to-b from-red-top to-red-bottom h-60 w-full rounded-b-3xl">
        <div className="flex justify-end">
          <img src={Logo} alt="Logo_kslplus" className="opacity-30" />
        </div>
      </div>
    </Fragment>
  );
};

export default CardBody;
