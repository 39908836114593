const Popup = ({ icon, title, description, confirmButton, cancelButton }) => {

  return (
    <div className="fixed inset-0 bg-super-black bg-opacity-30 flex justify-center items-center z-10">
      <div className="max-w-3xl mx-auto mt-4 px-4 tablet:px-20 w-full">
        <div className="bg-white pt-4 pb-6 px-4 rounded-lg text-center">
          <div className="text-dark">
            <div className="mt-4 mb-6 flex justify-center">
              {icon}
            </div>
            <span className="text-xl font-bold">{title}</span>
            <div className="my-8">
              {description}
            </div>
            <div className="flex justify-between gap-2">
              {cancelButton}
              {confirmButton}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Popup;
