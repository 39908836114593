import { createSlice } from '@reduxjs/toolkit';
import issueService from '../../services/issueService';
import { axiosErrorHandler } from '../../utils/error/handle';

export const issueSlice = createSlice({
  name: 'issue',
  initialState: {
    loading: false,
    success: false,
    error: null,
    result: null,
  },
  reducers: {
    loading: (state) => {
      state.loading = true;
      state.success = false;
      state.error = null;
    },
    setCreateIssueStatus: (state, action) => {
      state.loading = false;
      state.success = action.payload.success;
    },
    setError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    setResult: (state, action) => {
      state.result = action.payload;
    },
    reset: () => {
      return {
        loading: false,
        success: false,
        error: null,
      };
    },
    clearError: (state) => {
      state.error = null;
    },
  },
});

export const {
  loading,
  setCreateIssueStatus,
  setError,
  reset,
  setResult,
  clearError,
} = issueSlice.actions;

export const createIssue = (payload) => async (dispatch) => {
  try {
    dispatch(loading());
    const result = await issueService.CreateIssue(payload);
    dispatch(setResult(result.data));
    dispatch(setCreateIssueStatus(result.data));
  } catch (e) {
    console.log('e', e);
    dispatch(setError(axiosErrorHandler(e)));
  }
};

export const selectCreateIssueStatus = (state) => state.issue.success;
export const selectCreateIssueLoading = (state) => state.issue.loading;
export const selectCreateIssueError = (state) => state.issue.error;
export const getIssue = (state) => state.issue;

export default issueSlice.reducer;
