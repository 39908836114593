import config from '../config'
import Button from './Buttons'

const SuccessPopup = () => {
  return (
    <div className="fixed inset-0 bg-super-black bg-opacity-30 flex justify-center items-center z-10">
      <div className="max-w-3xl mx-auto mt-4 px-4 tablet:px-20 w-full">
        <div className="bg-white pt-4 pb-6 px-4 rounded-lg text-center">
          <div className="text-dark">
            <span className="text-xl font-bold">บันทึกข้อมูล</span>
            <div className="my-8">
              แอดมินจะทำการตรวจสอบ <br />
              และดำเนินการติดต่อคุณกลับโดยเร็วที่สุด
            </div>
            <Button label="รับทราบ" onClick={() => location.href = config.redirectUrl + '/profile'} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default SuccessPopup
